var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{attrs:{"id":"app"}},[(!_vm.publicPages.includes(_vm.currentPath) && _vm.authorized)?_c('div',{staticClass:"not-public",class:{
      'scroll-pages': _vm.scrollPages.includes(this.$router.currentRoute.name),
    }},[_c('TopBar',{attrs:{"drawer":_vm.drawer,"mini":_vm.mini},on:{"change-drawer":_vm.handleChangeDrawer}}),_c('Navigation',{attrs:{"mini":_vm.mini,"drawer":_vm.drawer},on:{"change-drawer":_vm.handleChangeDrawer}}),_c('v-main',{class:{
        'main-content': _vm.validatePath,
      }},[_c('v-container',{staticClass:"app-content",attrs:{"fluid":""}},[_c('router-view',{key:_vm.$route.fullPath,attrs:{"drawerApp":_vm.drawer},on:{"change-drawer":_vm.handleChangeDrawer}})],1),_c('v-footer',{staticClass:"footer"},[_c('FooterBar')],1)],1)],1):_vm._e(),(_vm.publicPages.includes(_vm.currentPath))?_c('div',{staticClass:"public"},[(
        ['reset-password', 'login', 'forgot-password'].includes(
          this.$router.currentRoute.name
        )
      )?_c('v-main',[_c('router-view'),_c('v-footer',[_c('FooterBar')],1)],1):_c('router-view')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }